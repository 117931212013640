import {Link, useSearchParams} from 'react-router-dom';
import * as S from './style/FooterStyle'
import {useMediaQuery} from "react-responsive";

function Footer() {

    const isMobile = useMediaQuery({query: '(min-width:0) and (max-width: 480px)'});


    const hideMobileFooterUrl = ['claiminsurance','insuranceapplication','renewinsurance','signupinsurance','chatbox', 'login', 'signup', 'findpassword', 'typeofsignup', 'newpassword' , 'registergoods', '/mypage/myinfoadmin','insurancedetail','goodsdetail'];
    const hidePCFooterUrl = ['chatbox' ,'login' , 'signup'  ,'findpassword' , 'typeofsignup', 'newpassword', 'registergoods','insurancedetail','goodsdetail'];
    const paddingUpPCUrl = ['insurancedetail', 'goodsdetail']
    const paddingUpMobileUrl = ['insurancedetail','claiminsurance','insuranceapplication','signupinsurance']


    const [searchParams, setSearchParams] = useSearchParams();


    return (
        <>
            {isMobile
            ?
                <S.Wrapper $display={window.location.pathname === '/mypage/myinfoadmin' ? hideMobileFooterUrl.includes(window.location.pathname) : hideMobileFooterUrl.includes(window.location.pathname.split('/')[1])}>
                    <S.Ars>
                        <S.ArsNumber to={'tel:0220380828'} title={'고객센터 전화 연결'}>
                            <p>고객센터</p>
                            <S.CallIcon aria-hidden={true} focusable={false}/>
                            <p>02-2038-0828 (ARS 1번)</p>
                        </S.ArsNumber>
                        <S.ArsHour>
                            평일 09:00~18:00 (점심시간: 12:00~13:00, 공휴일 휴무)
                        </S.ArsHour>
                    </S.Ars>
                    <S.Container $paddingUp={paddingUpMobileUrl.includes(window.location.pathname.split('/')[1]) || searchParams?.get('menu') === 'write'}>
                        <S.Contents>
                            <S.PolicyAndIntroduce>
                                <Link to='/privacypolicy'>개인정보처리방침</Link>
                                <Link to='/aboutus'>회사소개</Link>
                            </S.PolicyAndIntroduce>
                            <S.CompanyDescription>
                                (주)이디피랩 | 대표 심준원<br/>
                                (03187) 서울특별시 종로구 종로 6 서울창조경제혁신센터 5, 6층 (서린동, 광화문우체국)<br/>
                                사업자등록번호 645-87-01574 | 손해보험협회 심의필 제37862호 (2021.01.08)<br/>
                                통신판매업 신고: 제2022-서울영등포-0500호<br/>
                                보험대리점: 휠체어코리아닷컴(제2018010039호)<br/>
                            </S.CompanyDescription>
                            {/* <Link title="새창"
                                  to="http://www.wa.or.kr/board/list.asp?BoardID=0006"
                                  target="_blank">
                                <img
                                    alt="(사)한국장애인단체총연합회 한국웹접근성인증평가원 웹 접근성 우수사이트 인증마크(WA인증마크)"
                                    src="https://kr.object.fin-ncloudstorage.com/wheelchairkorea/a11y/231226%3D%EC%A0%95%EB%B3%B4%ED%86%B5%EC%8B%A0%EC%A0%91%EA%B7%BC%EC%84%B1%20%ED%92%88%EC%A7%88%EC%9D%B8%EC%A6%9D%20%EB%A7%88%ED%81%AC.png"
                                />
                            </Link> */}
                        </S.Contents>

                    </S.Container>
                </S.Wrapper>
                :
                <S.Wrapper
                    $display={hidePCFooterUrl.includes(window.location.pathname.split('/')[1])}>
                    <S.ArsContainer>
                        <S.Ars>
                            <S.ArsNumber2>
                                <p>고객센터</p>
                                <S.CallIcon aria-hidden={true} focusable={false}/>
                                <p>02-2038-0828 (ARS 1번)</p>
                            </S.ArsNumber2>
                            <S.ArsHour>
                                평일 09:00~18:00 (점심시간: 12:00~13:00, 공휴일 휴무)
                            </S.ArsHour>
                        </S.Ars>
                    </S.ArsContainer>
                    <S.Container
                        $paddingUp={paddingUpPCUrl.includes(window.location.pathname.split('/')[1])}
                    >
                        <S.Contents>
                                <S.PolicyAndIntroduce>
                                    <Link to='/privacypolicy'>개인정보처리방침</Link>
                                    <Link to='/aboutus'>회사소개</Link>
                                </S.PolicyAndIntroduce>
                                <S.CompanyDescription>
                                    (주)이디피랩 | 대표 심준원 | (03187) 서울특별시 종로구 종로 6 서울창조경제혁신센터 5, 6층 (서린동, 광화문우체국)<br />
                                    사업자등록번호 645-87-01574 | 손해보험협회 심의필 제37862호 (2021.01.08)<br/>
                                    통신판매업 신고: 제2022-서울영등포-0500호 | 보험대리점: 휠체어코리아닷컴(제2018010039호)<br/>
                                </S.CompanyDescription>

                            {/* <Link title="새창"
                                  to="http://www.wa.or.kr/board/list.asp?BoardID=0006"
                                  target="_blank">
                                <img
                                alt="(사)한국장애인단체총연합회 한국웹접근성인증평가원 웹 접근성 우수사이트 인증마크(WA인증마크)"
                                src="https://kr.object.fin-ncloudstorage.com/wheelchairkorea/a11y/231226%3D%EC%A0%95%EB%B3%B4%ED%86%B5%EC%8B%A0%EC%A0%91%EA%B7%BC%EC%84%B1%20%ED%92%88%EC%A7%88%EC%9D%B8%EC%A6%9D%20%EB%A7%88%ED%81%AC.png"
                               />
                            </Link> */}
                        </S.Contents>
                    </S.Container>
                </S.Wrapper>
            }

        </>

    );
}

export default Footer;
